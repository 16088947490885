<template>
  <WsUpdate
    :modelName="modelName"
    :id="_id"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="pageSetting.secondary"
    titleKey="name"
  >
  </WsUpdate>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_product";
export default {
  metaInfo() {
    return {
      title: `${this.$t("edit")}${this.label}`,
    };
  },
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "商品基本資訊",
              fields: ["no", "name", "subtitle"],
            },
            {
              title: "商品銷售資訊",
              fields: [
                "cost",
                "price",
                "tax",
                "stock_count",
                "stock_alert_count",
                "max_buyable_count",
                "storage_space",
                "store_temperature",
                "purchaser",
                "ranking_score",
                "store_house_class",
                "store_house_subclass",
              ],
            },
            {
              title: "商品其他資訊",
              fields: [
                "spec",
                "weight_capacity",
                "weight_capacity_unit",
                "show_weight_capacity",
                "on_time",
                "off_time",
                "is_active",
                "shop_shop_classes",
                "featured_classes",
              ],
            },
            {
              title: "商品介紹",
              fields: [
                "cover_image",
                "images",
                "description",
                "shop_product_cover_frame",
                "suggests",
              ],
            },
          ],
        },
      ],
    },
  }),
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>